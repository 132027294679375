"use client";
import React, {useEffect, useState } from 'react'
function ScrollTop() {
    const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 800) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {isVisible && (
         <div className="go-up" onClick={scrollToTop}><i className="fa fa-chevron-up"></i></div>
      )}
    </>
  )
}

export default ScrollTop